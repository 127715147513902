<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Редактировать
    </template>
    <CreateFormLayout>
      <template v-slot:title__text>
        Редактирование прошивки
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные прошивки    -->
        <div>
          <FormCardTitle title="Данные прошивки" />
          <FormInputBlock>
            <ValidationSelectField
              class="validation-select-field"
              label="Чип"
              :items="[
                { value: 'ESP', text: 'ESP' },
                { value: 'NRF', text: 'NRF' }
              ]"
              v-model="chip"
            />

            <ValidationInputField
              rules="number"
              label="Версия чипа"
              v-model="board_version"
              validate-name="версия чипа"
            ></ValidationInputField>

            <ValidationInputField
              rules=""
              label="Версия прошивки"
              v-model="version"
              validate-name="версия"
            ></ValidationInputField>
            <ValidationSelectField
              class="validation-select-field"
              label="Тестовая"
              :items="[
                { value: true, text: 'Да' },
                { value: false, text: 'Нет' }
              ]"
              v-model="isTest"
              :rules="'required'"
            />

            <ValidationSelectField
              class="validation-select-field"
              label="Статус"
              :items="[
                // { value: null, text: '--' },
                { value: true, text: 'Активен' },
                { value: false, text: 'Не активен' }
              ]"
              v-model="isActive"
            />
          </FormInputBlock>
        </div>
        <!-- Данные прошивки    -->

        <!--    Информация о файле    -->
        <div>
          <FormCardTitle>
            <p class="data__text">Загрузка файла</p>
          </FormCardTitle>
          <FormInputBlock>
            <ValidationInputField
              label="Текущий файл"
              v-model="fileLog"
              validate-name="текущий файл"
            ></ValidationInputField>

            <ValidationFileField
              accept=".bin"
              v-model="file"
              label="Загрузка нового файла"
            />
          </FormInputBlock>
        </div>
        <div>
          <FormCardTitle title="Комментарий" />
          <FormInputBlock>
            <ValidationTextareaField
              class=" grid-column_1 grid-column_span_2 "
              rules="max:500"
              v-model="comment"
              label="Текст комментария"
              validate-name="текст комментария"
            />
          </FormInputBlock>
        </div>
        <!--    Информация о файле    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click.prevent="handleSubmit(editFirmwares)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>

    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import MainLayout from "@/components/layouts/MainLayout";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import ValidationFileField from "@/components/ValidationField/ValidationFileField";
import ValidationTextareaField from "@/components/ValidationField/ValidationTextareaField";

import { updatePatchFirmwaresRequest } from "@/helpers/api/controller-firmwares";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "EditFirmwaresPage",
  components: {
    ValidationFileField,
    ValidationSelectField,
    ValidationInputField,
    MainLayout,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout,
    ValidationTextareaField
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    getCurrentRole: {
      get() {
        return this.$store.getters.getCurrentRole;
      }
    },
    chip: {
      get() {
        return this.$store.getters.getFirmwareInfo?.chip;
      },
      set(newValue) {
        this.$store.commit("CHANGE_FIRMWARE_INFO_VALUE", {
          fieldName: "chip",
          value: newValue
        });
      }
    },
    version: {
      get() {
        return this.$store.getters.getFirmwareInfo?.version;
      },
      set(newValue) {
        this.$store.commit("CHANGE_FIRMWARE_INFO_VALUE", {
          fieldName: "version",
          value: newValue
        });
      }
    },
    board_version: {
      get() {
        return this.$store.getters.getFirmwareInfo?.board_version;
      },
      set(newValue) {
        this.$store.commit("CHANGE_FIRMWARE_INFO_VALUE", {
          fieldName: "board_version",
          value: newValue
        });
      }
    },
    comment: {
      get() {
        return this.$store.getters.getFirmwareInfo?.comment;
      },
      set(newValue) {
        this.$store.commit("CHANGE_FIRMWARE_INFO_VALUE", {
          fieldName: "comment",
          value: newValue
        });
      }
    },
    isTest: {
      get() {
        return this.$store.getters.getFirmwareInfo?.is_test;
      },
      set(newValue) {
        this.$store.commit("CHANGE_FIRMWARE_INFO_VALUE", {
          fieldName: "is_test",
          value: newValue
        });
      }
    },
    isActive: {
      get() {
        return this.$store.getters.getFirmwareInfo?.is_active;
      },
      set(newValue) {
        this.$store.commit("CHANGE_FIRMWARE_INFO_VALUE", {
          fieldName: "is_active",
          value: newValue
        });
      }
    },

    fileLog: {
      get() {
        return this.$store.getters.getFirmwareInfo?.file;
      }
    },

    file: {
      get() {
        return this.$store.getters.getNewFile;
      },
      set(newVal) {
        this.$store.commit("CHANGE_FIRMWARE_INFO_VALUE_FILE", {
          value: newVal
        });
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("clearEditCompany");
    next();
  },
  created() {
    this.$store.dispatch("fetchFirmwareInfo", {
      id: this.$route.params.id
    });
  },
  methods: {
    async editFirmwares() {
      let {
        id,
        chip,
        version,
        is_active,
        is_test,
        comment,
        board_version
      } = this.$store.getters.getFirmwareInfo;
      const formData = new FormData();

      formData.append("chip", chip);
      formData.append("version", version);
      formData.append("is_active", is_active);
      formData.append("is_test", is_test);
      formData.append("board_version", board_version);
      formData.append("comment", comment);
      if (this.$store.getters.getNewFile != null) {
        formData.append("file", this.$store.getters.getNewFile);
      }

      return await updatePatchFirmwaresRequest({
        id: id,
        data: formData
      })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.firmwares)
          );
        })
        .then(() => {
          this.$router.go(-1);
        });
    }
  }
};
</script>

<style scoped></style>
