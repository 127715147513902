var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MainLayout',{scopedSlots:_vm._u([{key:"navbar-title",fn:function(){return [_vm._v(" Редактировать ")]},proxy:true}])},[_c('CreateFormLayout',{scopedSlots:_vm._u([{key:"title__text",fn:function(){return [_vm._v(" Редактирование прошивки ")]},proxy:true},{key:"title-flex-right",fn:function(){return [_c('div')]},proxy:true},{key:"btns",fn:function({ handleSubmit }){return [_c('a',{staticClass:"btn btn_color_white",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Отмена ")]),_c('v-btn',{staticClass:"btn btn-green",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.editFirmwares)}}},[_vm._v(" Сохранить ")])]}}])},[[_c('div',[_c('FormCardTitle',{attrs:{"title":"Данные прошивки"}}),_c('FormInputBlock',[_c('ValidationSelectField',{staticClass:"validation-select-field",attrs:{"label":"Чип","items":[
              { value: 'ESP', text: 'ESP' },
              { value: 'NRF', text: 'NRF' }
            ]},model:{value:(_vm.chip),callback:function ($$v) {_vm.chip=$$v},expression:"chip"}}),_c('ValidationInputField',{attrs:{"rules":"number","label":"Версия чипа","validate-name":"версия чипа"},model:{value:(_vm.board_version),callback:function ($$v) {_vm.board_version=$$v},expression:"board_version"}}),_c('ValidationInputField',{attrs:{"rules":"","label":"Версия прошивки","validate-name":"версия"},model:{value:(_vm.version),callback:function ($$v) {_vm.version=$$v},expression:"version"}}),_c('ValidationSelectField',{staticClass:"validation-select-field",attrs:{"label":"Тестовая","items":[
              { value: true, text: 'Да' },
              { value: false, text: 'Нет' }
            ],"rules":'required'},model:{value:(_vm.isTest),callback:function ($$v) {_vm.isTest=$$v},expression:"isTest"}}),_c('ValidationSelectField',{staticClass:"validation-select-field",attrs:{"label":"Статус","items":[
              // { value: null, text: '--' },
              { value: true, text: 'Активен' },
              { value: false, text: 'Не активен' }
            ]},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}})],1)],1),_c('div',[_c('FormCardTitle',[_c('p',{staticClass:"data__text"},[_vm._v("Загрузка файла")])]),_c('FormInputBlock',[_c('ValidationInputField',{attrs:{"label":"Текущий файл","validate-name":"текущий файл"},model:{value:(_vm.fileLog),callback:function ($$v) {_vm.fileLog=$$v},expression:"fileLog"}}),_c('ValidationFileField',{attrs:{"accept":".bin","label":"Загрузка нового файла"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1),_c('div',[_c('FormCardTitle',{attrs:{"title":"Комментарий"}}),_c('FormInputBlock',[_c('ValidationTextareaField',{staticClass:"grid-column_1 grid-column_span_2",attrs:{"rules":"max:500","label":"Текст комментария","validate-name":"текст комментария"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1)]],2),_c('v-overlay',{attrs:{"value":_vm.loading,"z-index":"9999"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }